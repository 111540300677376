<template>
  <div style="height: 100vh; width: 100vw;">
    <div @click="$router.push('/')" style="padding: 20px 0 0 20px;font-weight: bolder; color: white;font-size: 20px; cursor: pointer;">
      Mortgage Envy
    </div>
    <div
      v-if="loaded"
      class="d-flex justify-content-center align-items-center full-height"
    >
      <div
        class="col-5 align-self-center"
      >
        <app-collapse
          accordion
          type="margin"
        >
          <div
            class="mt-2"
          >
            <app-collapse-item
              :title="questions.questionOne.question"
            >
             <div class="row">
               <div class="col-12">
                 <hwa-input
                         v-model="questions.questionOne.userProvidedAns.amount"
                         placeholder=Amount
                 />
               </div>
              </div>
            </app-collapse-item>
          </div>
        </app-collapse>
        <div class="mt-2" />

        <div class="d-flex">
          <hwa-button
            icon="ArrowLeftCircleIcon"
            label="PREVIOUS"
            @onClick="$router.back()"
          />
          <div class="ml-1" />
          <hwa-button
                  :disabled="!valid"
                  label="NEXT"
                  :loading="nextLoading"
                  @onClick="onNext()"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center full-height"
    >
      <grid-loader
        :loading="true"
        color="white"
      />

    </div>
  </div>
</template>

<script>
import { BListGroup, BSpinner } from 'bootstrap-vue'
import HwaListItem from '@/layouts/components/HwaListItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default {
  name: 'StartPurchase',
  components: {
    HwaButton,
    HwaInput,
    HwaListItem,
    BListGroup,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
    GridLoader,
  },
  data() {
    return {
      loaded: false,
      nextLoading: false,
      questions: {
        questionOne: {
          id: null,
          question: '',
          answers: [],
          userProvidedAns: {
            amount: null,
          },
        },
      },
    }
  },
  computed: {
    valid() {
      return !!this.questions.questionOne.userProvidedAns.amount
    },
  },
  mounted() {
    this.getStepOneQuestions()
  },
  methods: {
    onNext() {
      const id = localStorage.getItem('anonymous')
      this.nextLoading = true
      this.$http.post(`api/questions/refinance/2/${id}?application_id=${this.$store.state.auth.applicationId}`, this.questions).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            text: 'First step done, lets move on.',
            variant: 'success',
          },
        })
        this.$store.state.auth.stepTwoCompleted = true
        this.nextLoading = false
        this.$router.push({ name: 'r-step-3' })
      }).catch(err => {
        this.nextLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            icon: 'CheckCircleIcon',
            text: 'Something went wrong, try again.',
            variant: 'danger',
          },
        })
      })
    },
    getStepOneQuestions() {
      const id = localStorage.getItem('anonymous')
      this.$http.get(`api/questions/refinance/2/${id}?update=${this.$store.state.auth.updateRequest}&application_id=${this.$store.state.auth.applicationId}`).then(res => {
        this.questions = res.data
        this.loaded = true
      })
    },
  },
}
</script>

<style scoped>

</style>
