<template>
  <b-list-group-item
    href="#"
    class="d-flex"
  >
    <span
      v-if="selected"
      class="mr-1"
    >
      <feather-icon
        icon="CheckCircleIcon"
        class="text-primary"
        size="18"
      />
    </span>
    <span>{{ title }}</span>
  </b-list-group-item>
</template>

<script>
import { BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'HwaListItem',
  components: {
    BListGroupItem,
  },
  props: {
    title: { type: String, default: 'No Title' },
    selected: { type: Boolean, default: false },
  },
}
</script>

<style scoped>

</style>
