<template>
    <b-form-input
      v-if="simpleInput"
      :id="name"
      :value="value"
      :state="state"
      :placeholder="placeholder||label"
      :type="type"
      @input="inputChanged"
      @keypress.enter="enterPressed"
    /> 
  <validation-provider
    v-else
    #default="{ errors }"
    :vid="name"
    :name="formatName(name)"
    :rules="rules"
  >
    <b-form-group>
      <label
        v-if="showLabel"
        :for="label"
      >{{ label }}</label>
      <b-input-group
        v-if="!masked"
        class="input-group-merge "
      >
        <b-input-group-prepend
          v-if="prefixIcon"
          is-text
        >
          <feather-icon
            class="cursor-pointer"
            :icon="prefixIcon"
            @click="prefixIconClicked"
          />
        </b-input-group-prepend>
        <b-form-input
          :id="name"
          :value="value"
          :state="errors.length > 0 ? false:null"
          :name="name"
          :placeholder="placeholder||label"
          :type="type"
          :readonly="readOnly"
          @input="inputChanged"
          @keypress.enter="enterPressed"
        />
      </b-input-group>
      <cleave
        v-else
        class="form-control"
        :raw="false"
        :value="value"
        :options="options[mask]"
        :name="name"
        :placeholder="placeholder||label"
        @input="inputChanged"
        @keypress.enter="enterPressed"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationProvider } from 'vee-validate'
import {
  required, email, numeric, integer, tin, ssn, phone, max_value,
} from '@core/utils/validations/validations'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  name: 'HwaInput',
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    ValidationProvider,
    Cleave,
  },
  props: {
    immediate: {
      type: Boolean,
      default: true,
    },
    masked: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: 'phone',
    },
    name: {
      type: String,
      default: 'input',
    },
    label: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    simpleInput: {
      type: Boolean,
      default: false,
    },
    suffixIcon: {
      type: String,
      default: 'Edit2Icon',
    },
    prefixIcon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: String,
      default: '',
    },
    validate: {
      type: String,
      default: '',
    },

    state: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      email,
      numeric,
      tin,
      ssn,
      phone,
      integer,
      max_value,
      options: {
        ssn: {
          delimiter: '-',
          blocks: [3, 2, 4],
          uppercase: true,
        },
        phone: {
          delimiter: '-',
          blocks: [3, 3, 4],
          uppercase: true,
        },
      },
    }
  },
  methods: {
    formatName(name) {
      return name.replaceAll('_', ' ')
    },
    suffixIconClicked(e) {
      this.$emit('onSuffixIconClick', e)
    },
    prefixIconClicked(e) {
      this.$emit('onPrefixIconClick', e)
    },
    enterPressed(e) {
      this.$emit('onEnterPressed', e.target.value)
    },
    inputChanged(e) {
      this.$emit('input', e)
    },
  },
}
</script>

<style scoped>
  input {
    color:black;
  }
</style>
